import React from "react";
import { BrowserRouter } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import { ExternalTransfer } from "./ExternalTransfer/ExternalTransfer";
import DataProvider from "./dataContext";

const App = () => {
  return (
    <DataProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/external/transfer" element={<ExternalTransfer />} />
        </Routes>
      </BrowserRouter>
    </DataProvider>
  );
};

export default App;
