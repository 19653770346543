import { useEffect, useState } from "react";
import useApi from "../api/api";
import { useLocation } from "react-router-dom";

const FiBranding = () => {
  const api = useApi();
  const location = useLocation();

  const defaultBranding = {
    receiving_colors: {
      receiving_bg_primary: "#ffffff",
      receiving_bg_secondary: "#edf3fc",
      receiving_bg_tertiary: "#1d74ec",
      receiving_text: "#282f38",
    },
    receiving_logo: {
      logo_bg: "#ffffff",
      logo_height: "0px",
      logo_width: "0px",
      logo_vertical_margin: "10px",
    },
    receiving_logo_url: "",
    advertising: {
      advertising_description: undefined,
      advertising_link: undefined,
      advertising_button_text: undefined,
    },
    button_indexes: {
      paypal_index: 1,
      venmo_index: 2,
      p2c_index: 3,
      decline_index: 11,
      p2a_index: -1,
      prizeout_index: -1,
      prizeout_widget: false,
    },
    prizeout_partner: {
      partner_id: "fb5f8b37-932d-4006-b58b-121afbe5b01d",
      environment: "sandbox",
    },
  };

  const [branding, setBranding] = useState(defaultBranding);
  const root = document.querySelector(":root");

  useEffect(() => {
    const transferId = location.search.split("?t=")[1].split("&i=")[0];

    const getFiBranding = async () => {
      try {
        const response = await api.get(`branding/transfer/ui/v2/${transferId}`);
        setBranding(response.data);
      } catch (error) {
        console.warn("get branding error", error);
      }
    };
    getFiBranding();
  }, [api, location]);

  const isColor = (str) => {
    if (/^#[0-9A-F]{6}$/i.test(str) === true) {
      return true;
    }
  };

  if (branding.receivingColors) {
    branding.receiving_colors= branding.receivingColors
    branding.receiving_logo= branding.receivingLogoStyling
    branding.receiving_logo_url= branding.receivingLogoUrl
    branding.button_indexes= branding.buttonIndexes
    branding.prizeout_partner= branding.prizeoutPartner
    branding.advertising = branding.advertisingInfo
  }

  // check if all branding colors are valid hex codes and set css variables if so
  if (
    branding.receiving_colors &&
    isColor(branding.receiving_colors.receiving_bg_primary) &&
    isColor(branding.receiving_colors.receiving_bg_secondary) &&
    isColor(branding.receiving_colors.receiving_bg_tertiary) &&
    isColor(branding.receiving_colors.receiving_text)
  ) {
    root.style.setProperty(
      "--receiving_bg_primary",
      branding.receiving_colors.receiving_bg_primary
    );
    root.style.setProperty(
      "--receiving_bg_secondary",
      branding.receiving_colors.receiving_bg_secondary
    );
    root.style.setProperty(
      "--receiving_bg_tertiary",
      branding.receiving_colors.receiving_bg_tertiary
    );
    root.style.setProperty(
      "--receiving_text",
      branding.receiving_colors.receiving_text
    );
  } else {
    setBranding({
      ...branding,
      receiving_colors: defaultBranding.receiving_colors,
    });
  }

  if (branding.receiving_logo && isColor(branding.receiving_logo.logo_bg)) {
    root.style.setProperty("--logo_bg", branding.receiving_logo.logo_bg);
  }

  if (
    branding.button_indexes &&
    !(
      Number.isInteger(branding.button_indexes.paypal_index) ||
      Number.isInteger(branding.button_indexes.venmo_index) ||
      Number.isInteger(branding.button_indexes.p2c_index) ||
      Number.isInteger(branding.button_indexes.decline_index) ||
      Number.isInteger(branding.button_indexes.p2a_index) ||
      Number.isInteger(branding.button_indexes.prizeout_index)
    )
  ) {
    setBranding({
      ...branding,
      button_indexes: defaultBranding.button_indexes,
    });
  }

  if (
    branding.receiving_logo &&
    Math.abs(branding.receiving_logo.logo_height) >= 0 &&
    Math.abs(branding.receiving_logo.logo_width) >= 0 &&
    Math.abs(branding.receiving_logo.logo_vertical_margin) >= 0
  ) {
    root.style.setProperty(
      "--logo_height",
      `${branding.receiving_logo.logo_height}px`
    );
    root.style.setProperty(
      "--logo_width",
      `${branding.receiving_logo.logo_width}px`
    );
    root.style.setProperty(
      "--logo_vertical_margin",
      `${branding.receiving_logo.logo_vertical_margin}px`
    );
  }

  if (typeof branding.receiving_logo_url === "string") {
    root.style.setProperty(
      "--receiving_logo_url",
      `url(${branding?.receiving_logo_url})`
    );
  }

  return { branding };
};
export default FiBranding;
