import { useMemo } from "react";
import axios from "axios";

const useAPI = () => {
  const location = window.location;
  const transferId = location.search.split("?t=")[1].split("&i=")[0];
  let baseUrl = process.env.REACT_APP_API_URL;

  if (transferId.length !== 36) {
    baseUrl = process.env.REACT_APP_DISBURSEMENTS_API_URL;
  }

  const httpRequest = useMemo(
    () =>
      axios.create({
        baseURL: baseUrl,
        withCredentials: true,
        headers: {
          "Content-Type": "application/json",
        },
      }),
    [baseUrl]
  );

  let api = httpRequest;

  return api;
};

export default useAPI;
