import React, { useState, useEffect, createContext } from "react";
import useApi from "./api/api";

export const DataContext = createContext();

const DataProvider = ({ children }) => {
  const api = useApi();
  const location = window.location;
  const [transferData, setTransferData] = useState({});

  useEffect(() => {
    const inviteId = location.search.split("&i=")[1];
    const transferId = location.search.split("?t=")[1].split("&i=")[0];

    const getTransferData = async () => {
      try {
        const response = await api.get(
          `/external/transfer/${transferId}/${inviteId}`
        );
        setTransferData(response.data);
      } catch (error) {
        console.warn("get transferData error", error);
        if (!error.response) {
          setTransferData({
            transfer_state: "server_down",
          });
        }
      }
    };
    getTransferData();
  }, [api, location]);

  return (
    <DataContext.Provider value={{ transferData, setTransferData }}>
      {children}
    </DataContext.Provider>
  );
};

export default DataProvider;
